/*----------------------------------------------------------------------------*
	$GLOBAL
	Carga todos los recursos requeridos y los compila en un único archivo
*----------------------------------------------------------------------------*/
/**
 *
 * == $load-grid ==
 * Definir en true para cargar la grilla.
 *
 */
.g-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 768px) {
  .g-container {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 769px) {
  .g-container {
    max-width: 750px;
  }
}
@media only screen and (min-width: 992px) {
  .g-container {
    max-width: 970px;
  }
}
@media only screen and (min-width: 1200px) {
  .g-container {
    max-width: 1134px;
  }
}
.g-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.g-row > * {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
[class*=g-col] {
  width: calc(100% - 3rem);
}
* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  vertical-align: baseline;
}
body {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  color: #666d73;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
ul,
ol {
  list-style: none;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 700;
}
h1,
.h1 {
  font-size: 3.2rem;
}
h2,
.h2 {
  font-size: 2.5rem;
}
h3,
.h3 {
  font-size: 1.9rem;
}
h4,
.h4 {
  font-size: 1.6rem;
}
h5,
.h5 {
  font-size: 1.4rem;
}
h6,
.h6 {
  font-size: 1.2rem;
}
p {
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
}
p:last-child {
  margin-bottom: 0;
}
b,
strong {
  color: #474c51;
}
a {
  outline: none;
  text-decoration: none;
  color: #35b4db;
  transition: all 0.2s ease;
}
a:hover {
  color: #1d82a2;
}
img {
  max-width: 100%;
  height: auto;
  border: none;
}
button,
input,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}
button,
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}
button[disabled],
input[disabled] {
  cursor: default;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.o-btn {
  appearance: none;
  border: none;
  outline: 0;
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 22px 4px 22px;
  border-radius: 20px;
  border: 2px solid #35b4db;
  background: transparent;
  font-weight: 500;
  font-size: 1.8rem;
  color: #35b4db;
  transition: all 0.2s ease;
}
.o-btn:hover {
  background: #35b4db;
  color: #fff;
}
.o-btn [class*="icon"],
.o-btn img {
  margin-left: 8px;
  padding-top: 5px;
  font-size: 2rem;
}
.o-btn img {
  max-width: 20px;
}
.icon-der {
  padding: 0 12px 4px 15px;
}
.icon-iz {
  padding: 0 15px 4px 12px;
}
.icon-iz [class*="icon"] {
  margin-left: 0;
  margin-right: 8px;
}
.o-btn-cerrar {
  appearance: none;
  border: none;
  outline: 0;
  position: absolute;
  top: -25px;
  right: -25px;
  padding: 0;
  background: transparent;
  font-size: 2.5rem;
  color: #fff;
}
@media only screen and (max-width: 480px) {
  .o-btn-cerrar {
    top: -35px;
    left: 50%;
    margin-left: -1.25rem;
    right: auto;
  }
}
.o-btn-cerrar span {
  display: none;
}
.o-form-field,
.o-form-field--textarea,
.selectize-input {
  appearance: none;
  width: 100%;
  height: 40px;
  padding: 0 20px 3px 20px;
  border: 2px solid #c8cdd1;
  border-radius: 20px;
  outline: 0;
  background: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  color: #666d73;
  transition: all 0.3s ease;
}
.o-form-field::-webkit-input-placeholder,
.o-form-field--textarea::-webkit-input-placeholder,
.selectize-input::-webkit-input-placeholder {
  color: #94999d;
}
.o-form-field:-moz-placeholder,
.o-form-field--textarea:-moz-placeholder,
.selectize-input:-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.o-form-field::-moz-placeholder,
.o-form-field--textarea::-moz-placeholder,
.selectize-input::-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.o-form-field:-ms-input-placeholder,
.o-form-field--textarea:-ms-input-placeholder,
.selectize-input:-ms-input-placeholder {
  color: #94999d;
}
.o-form-field:hover,
.o-form-field--textarea:hover,
.selectize-input:hover {
  border-color: #bcc1c1;
}
.o-form-field:focus,
.o-form-field--textarea:focus,
.selectize-input:focus {
  background: #fff;
  color: #666d73;
}
.o-form-field--textarea {
  height: 150px;
  padding: 13px;
}
.o-form-check {
  display: flex;
}
.o-form-check__campo {
  display: none;
}
.o-form-check__campo + label,
.o-form-check__campo + span + label {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  font-family: 'icomoon' !important;
  font-size: 2rem;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9ca1a5;
  cursor: pointer;
}
.o-form-check__campo + label + span,
.o-form-check__campo + span + label + span {
  padding-top: 1px;
  font-size: 1.6rem;
  color: #4c668c;
  font-weight: 500;
}
.o-form-check__campo + label:hover,
.o-form-check__campo + span + label:hover {
  color: #81888d;
}
.o-form-check__campo[type="checkbox"] + label:before,
.o-form-check__campo[type="checkbox"] + span + label:before {
  content: "\ee6f";
}
.o-form-check__campo[type="radio"] + label:before,
.o-form-check__campo[type="radio"] + span + label:before {
  content: "\ee73";
}
.o-form-check__campo:checked + label {
  color: #35b4db;
}
.o-form-check__campo:checked + label:hover:before {
  color: #25a5cd;
}
.o-form-check__campo:checked[type="checkbox"] + label:before {
  content: "\ee6e";
}
.o-form-check__campo:checked[type="radio"] + label:before {
  content: "\ee76";
}
.selectize-input {
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: inline-flex !important;
  align-items: center;
  padding: 0 30px 0 20px;
}
.selectize-input > input {
  width: 100% !important;
  padding-bottom: 4px;
  border: none !important;
  font-size: 1.8rem !important;
  font-weight: 400 !important;
  color: #666d73 !important;
}
.selectize-input > input::-webkit-input-placeholder {
  color: #94999d;
}
.selectize-input > input:-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.selectize-input > input::-moz-placeholder {
  opacity: 1;
  color: #94999d;
}
.selectize-input > input:-ms-input-placeholder {
  color: #94999d;
}
.selectize-input > .item {
  flex: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-bottom: 3px;
  font-size: 1.8rem;
  font-weight: 400;
  color: #666d73;
}
.selectize-dropdown {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  top: calc(100% + 2px) !important;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.2);
  background: #fff;
  font-size: 1.5rem;
}
.selectize-dropdown .active {
  background-color: #f2f4f6;
}
.o-form-link {
  color: #868c91;
}
.o-form-link:hover {
  color: #35b4db;
}
span.o-form-error {
  display: block;
  padding-left: 20px;
  font-size: 1.5rem;
  color: #35b4db;
}
.o-heading-small,
.o-heading-small--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
}
.o-heading-small--center {
  text-align: center;
}
.o-heading-normal,
.o-heading-normal--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}
.o-heading-normal--center {
  text-align: center;
}
.o-heading-normal-2,
.o-heading-normal-2--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  font-weight: 500;
}
.o-heading-normal-2--center {
  text-align: center;
}
.o-heading-normal-3,
.o-heading-normal-3--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
}
.o-heading-normal-3--center {
  text-align: center;
}
.o-heading-normal-4,
.o-heading-normal-4--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
}
.o-heading-normal-4--center {
  text-align: center;
}
.o-heading-medium,
.o-heading-medium--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3rem;
  font-weight: 500;
}
.o-heading-medium--center {
  text-align: center;
}
.o-heading-large,
.o-heading-large--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3.3rem;
  font-weight: 500;
}
.o-heading-large--center {
  text-align: center;
}
.o-heading-large-2,
.o-heading-large-2--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
}
.o-heading-large-2--center {
  text-align: center;
}
.o-heading-huge,
.o-heading-huge--center {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 5.6rem;
  font-weight: 500;
}
.o-heading-huge--center {
  text-align: center;
}
.o-heading-1 {
  text-align: center;
  color: #4c668c;
}
.o-is-hidden {
  display: none;
}
.o-player-wrap {
  width: 100%;
  position: relative;
  padding: 56.25% 0 0 0;
  box-shadow: 0 2px 20px rgba(0,0,0,0.7);
}
.o-player-wrap iframe {
  position: absolute;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0;
}
.o-player-video {
  position: relative;
  width: 100% !important;
  border-radius: 7px;
  padding: 58% 0 0 0;
  background: rgba(12,74,56,0.8);
}
.o-player-video:before {
  position: absolute;
  top: 50%;
  left: 50%;
  color: $base4-color;
  font-size: 7rem;
  transform: translate(-50%, -50%);
}
.o-text-icon {
  display: flex;
  align-items: center;
}
.o-text-icon:before {
  margin-right: 10px;
  font-size: 2.2rem;
}
.c-header {
  padding: 7px 0;
  margin-bottom: 40px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.15);
  background: #fff;
}
.c-header__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .c-header__container {
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) {
  .c-header__logo {
    margin-bottom: 5px;
  }
}
.c-header__logo img {
  display: block;
  max-width: 200px;
}
