/*----------------------------------------------------------------------------*
	$C-HEADER
*----------------------------------------------------------------------------*/

/* ^.c-header
-----------------------------------------------------------------------------*/

.c-header
	padding: 7px 0
	margin-bottom: 40px
	box-shadow: 0 2px 10px rgba(#000, .15)
	background: #fff


.c-header__container
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	align-items: center

	+max('xl')
		justify-content: center


.c-header__logo
	+max('xl')
		margin-bottom: 5px
	img
		display: block
		max-width: 200px


/* ^Fin - .c-header
-----------------------------------------------------------------------------*/
